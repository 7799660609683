<template>
  <moe-page title="规格管理">
    <!-- 商品信息 -->
    <moe-table ref="specsTable" url="/shop/specs/querySpecsList" :params="specsParams" :number-show="false" row-key="id">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline"
          @click="$router.push('/specs/add')">添加规格</el-button>
      </template>
      <el-table-column prop="id" label="规格编号" min-width="130" />
      <el-table-column prop="name" label="规格名称" min-width="130">
        <template slot-scope="{ row }">
          <input v-if="row.edit" v-model="row.name">
          <div v-else>{{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="规格排序" min-width="130">
        <template slot-scope="{ row }">
          <input v-if="row.edit" :value="row.sort" @input="(value) => row.sort = value.replace(/^\.+|[^\d]/g,'')">
          <div v-else>{{ row.sort }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <template v-if="row.edit">
            <el-button type="success" size="mini" icon="el-icon-folder-checked" @click="specsAmendInfoSubmit(row)">保存</el-button>
            <el-button type="info" size="mini" icon="el-icon-folder-delete" @click="specsAmendInfoCancel()">取消</el-button>
          </template>
          <template v-else>
            <el-button type="warning" size="mini" icon="el-icon-edit" @click="specsAmendInfoEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="$router.push(`/specs/amend?id=${row.id}`)">规格值管理</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteShopSpecs(row.id)">删除</el-button>
          </template>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
import { cloneData } from '@/utils/lodash-es.js'
export default {
  name: 'SpecsList',
  data() {
    //日志筛选信息
    let specsParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
    };

    return {
      specsParams, //日志筛选信息
      oldTableData: []
    };
  },
  methods: {
    /**
     * 修改账号状态
     **/
    accountStatusChange(id, state) {
      // 调用修改账号状态API
      this.$moe_api.ACCOUNT_API.accountStatus({
        id,
        state,
      }).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success(state === 0 ? '启用成功' : '禁用成功');

          //刷新账号表格
          this.$refs.specsTable.loadingData();
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },


    /**
     * 提交规格信息修改
     **/
    specsAmendInfoSubmit({ id, name, sort }) {
      if (!name) {
        return this.$moe_msg.warning('请输入规格名称')
      }
      this.$moe_api.GOODS_API.specsUpdate({ id, name, sort })
        .then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('修改成功');
            this.$refs.specsTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
        .finally(() => {
          this.specsAmendLoad = false;
        });
    },

    specsAmendInfoCancel() {
      this.$refs.specsTable.searchData();
    },

    specsAmendInfoEdit(row) {
      row.edit = !row.edit;
    },

    /**
     * 删除规格
     **/
    deleteShopSpecs(id) {
      this.$moe_layer.confirm('是否要删除该规格 ?', () => {
        // console.log(id)
        // 调用删除账号API
        this.$moe_api.GOODS_API.deleteShopSpecs({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            //刷新广告表格
            this.$refs.specsTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },

  },
};
</script>